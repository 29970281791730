import MultichannelSdk from '../../api';
import { createProxy } from '../Proxy';
import { observable, runInAction, action, computed } from 'mobx';
import { EVENT_LIST } from '../constants';
import Compareable from '../../Comparable';
import { from, fromEvent, interval } from 'rxjs';
import { debounce, mergeMap, takeWhile } from 'rxjs/operators';
import Events from '@core/events';
import Actions from '@core/actions';

const SOUNDOPTION_STORAGE: string = 'soundOption';
const SOUNDCAPABILITIES_STORAGE: string = 'audioCapabilities';

export default class AudioManager extends Compareable {
  _api: MultichannelSdk;
  _proxy;
  _logger;
  _tmpStorage: any;
  _audio;

  @observable public deviceListObservable;
  @observable public outputDeviceListObservable;
  @observable public inputDeviceListObservable;

  @observable _hasMicrophone: boolean;
  @observable _microphonePermission;
  _hasSpeaker: boolean;

  _ringtoneVolume: number;
  _voiceaudioVolume: number;
  _workitemVolume: number;

  _voiceaudioDevice: string;
  _ringtoneDevices: Array<string> = [];
  _workitemDevices: Array<string> = [];
  _inputDevice: string;
  @observable _canSwitchDevices: boolean;

  @observable _isRinging: boolean;
  @observable _isRingingTest: boolean;
  @observable _isVoicePlaying: boolean;
  _audioElements: AudioElements;
  _customAudioElements = {};

  _mediaConstraints: any;

  public sameAsOutputDevice: SimpleMediaDeviceInfo;
  public readonly sameAsOutputDeviceId: string = 'sameAsOutput';
  private _audioContext: AudioContext;
  @observable private _audioContextGranted: boolean = false;
  @observable private _audioContextIssue: boolean = false;

  /**
   *
   * @param {VierComApi} api
   */
  constructor(api: MultichannelSdk) {
    super();
    this._proxy = createProxy(this);
    this._api = api;
    this._logger = this._api.debug('api.audio');

    this._audio = null;
    this._microphonePermission = null;

    this.deviceListObservable = [];
    this.outputDeviceListObservable = [];
    this.inputDeviceListObservable = [];

    this._hasMicrophone = false;
    this._hasSpeaker = true;

    this._ringtoneVolume = 100; // in percent
    this._voiceaudioVolume = 100; // in percent
    this._workitemVolume = 100;

    this._mediaConstraints = {
      audio: true,
      video: false,
    };

    this._isRingingTest = false;
    this._isRinging = false;
    this._isVoicePlaying = false;

    this._audioElements = {};

    this.sameAsOutputDevice = {
      deviceId: this.sameAsOutputDeviceId,
      label   : api.language.translate('Gleiches_Ausgabegerät'),
    };

    if (navigator) {
      // Make sure we re-check the available devices if the user plugs in a microphone.
      if (navigator.mediaDevices) {
        navigator.mediaDevices.ondevicechange = () => {
          this.checkDevices();
        };
      }
    }

    this._attachEventHandlers();

    this._registerActions();

    //@ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this._audioContext = new AudioContext();
    this.storeCapabilities();

    this._audioContext.addEventListener('statechange', action(() => {
      this._logger.trace('Statechange Audiocontext', this._audioContext.state);
      this._audioContextGranted = this._audioContext.state === AudioContextState.RUNNING;
      this.checkAudioContext();

      this.storeCapabilities(this._audioContextGranted);
      this._createRingtoneAudio();
      this._createVoiceAudio();
      this._createNonVoiceAudio(this._getNonVoiceAudioData());
      this._setSwitchDeviceAbility();
    }));

    const events = ['mouseenter', 'mousemove'];
    from(events).pipe(
      mergeMap(event => fromEvent(document, event)),
      takeWhile(() => this._audioContext.state !== AudioContextState.RUNNING),
      debounce(() => interval(50))
    ).subscribe(() => {
      this._audioContext.resume();
    });

    return this._proxy;
  }

  /**
   * Get the current list of all registered tabs
   */
  public get audioInstanceList(): AudioCapabilities[] {
    return this._api.storage.get('instances', SOUNDCAPABILITIES_STORAGE) || [];
  }
  public set audioInstanceList(list: AudioCapabilities[]) {
    this._api.storage.set('instances', list, SOUNDCAPABILITIES_STORAGE);
  }

  @action public storeCapabilities(granted: boolean = undefined) {
    const instanceList = this.audioInstanceList;
    const capabilities: AudioCapabilities = instanceList.find(item => item.instanceId === this._api.instanceId);
    const contextGranted = typeof granted !== 'undefined' ? granted : this._audioContextGranted;

    if (typeof capabilities === 'undefined') {
      instanceList.push({
        audioContext: contextGranted,
        instanceId  : this._api.instanceId
      });
    } else {
      capabilities.audioContext = contextGranted;
    }

    this.audioInstanceList = instanceList;
  }

  @action public clearCapabilities() {
    // Generate an unique sdk instance id
    const instanceList = this.audioInstanceList;
    const capabilities = instanceList.filter(item => item.instanceId !== this._api.instanceId);
    this.audioInstanceList = capabilities;
  }

  /**
   * This method registers an Event Handler on
   */
  @action public async accessMicrophone() {
    return new Promise(resolve => {
      //@ts-ignore
      if (navigator.permissions && !navigator.userAgent.includes('Firefox')) {
        //@ts-ignore
        navigator.permissions.query({ name: 'microphone' }).then(getState => {
          getState.onchange = this.checkPermission;
          runInAction(() => {
            this._microphonePermission = getState.state;
          });
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  }

  get audioContext() {
    return this._audioContext;
  }

  @computed get audioContextGranted() {
    return this._audioContextGranted;
  }

  @computed get audioContextIssue() {
    return this._audioContextIssue;
  }

  resumeContext() {
    return this._audioContext.resume();
  }

  get volume() {
    return navigator.userAgent.includes('Firefox')
      ? this.getRingtoneVolume()
      : this.getVoiceAudioVolume();
  }

  set volume(value) {
    this._logger.traceCall('volume', value);
    if (navigator.userAgent.includes('Firefox')) {
      this.setRingtoneVolume(value);
    } else {
      this.setVoiceAudioVolume(value);
    }
    if (this._audio) this._audio.volume = value / 100;
  }

  getWorkItemVolume() {
    let workitemVolumeInStorage = this._api.storage.get('workitemVolume', SOUNDOPTION_STORAGE);

    if (typeof workitemVolumeInStorage === 'undefined') {
      workitemVolumeInStorage = this._workitemVolume;
      this.setWorkItemVolume(this._workitemVolume);
    }

    return workitemVolumeInStorage;
  }

  setWorkItemVolume(value) {
    this._logger.traceCall('workitemVolume', value);
    this._workitemVolume = value;
    if (this._audioElements?.nonvoice?.volume) {
      this._audioElements.nonvoice.volume = value / 100;
    }
    this._api.storage.set('workitemVolume', value, SOUNDOPTION_STORAGE);
  }

  get mediaConstraints() {
    return this._mediaConstraints;
  }

  set mediaConstraints(value) {
    this._mediaConstraints = value;
  }

  @computed get hasMicrophonePermission(): boolean {
    return this._microphonePermission === 'granted' ? true : false;
  }

  @computed get microphonePermission() {
    return this._microphonePermission;
  }

  @computed get hasMicrophone(): boolean {
    return this._hasMicrophone;
  }

  @computed get hasPermissions(): boolean {
    return this.hasMicrophonePermission;
  }

  get hasSpeaker(): boolean {
    return this._hasSpeaker;
  }

  public getAudioElement(): any {
    this._logger.traceCall('getAudioElement');
    let audio: any;
    const audioElements = document.getElementsByTagName('audio');

    if (audioElements.length === 0) {
      audio = document.createElement('audio');
    } else {
      audio = audioElements[0];
    }

    if (audio.getAttribute('autoplay') === null) {
      audio.setAttribute('autoplay', '');
    }

    return audio;
  }

  ///////////////////////////////////////////////////////////////////////////////////
  // Localstorage soundoptions
  //
  //////////////////////////////////////////////////////////////////////////////////
  public getRingtoneVolume(): number {
    let ringtoneVolumeInStorage = this._api.storage.get('ringtoneVolume', SOUNDOPTION_STORAGE);

    if (typeof ringtoneVolumeInStorage === 'undefined') {
      ringtoneVolumeInStorage = this._ringtoneVolume;
      this.setRingtoneVolume(this._ringtoneVolume);
    }

    return ringtoneVolumeInStorage;
  }

  public getVoiceAudioVolume(): number {
    let voiceAudioVolumeInStorage = this._api.storage.get('voiceaudioVolume', SOUNDOPTION_STORAGE);

    if (typeof voiceAudioVolumeInStorage === 'undefined') {
      voiceAudioVolumeInStorage = this._voiceaudioVolume;
      this.setVoiceAudioVolume(this._voiceaudioVolume);
    }

    return voiceAudioVolumeInStorage;
  }

  public getRingtoneDevices(): Array<string> {
    let ringtoneDevicesInfoInStorage = this._api.storage.get('ringtoneDevices', SOUNDOPTION_STORAGE);

    if (!ringtoneDevicesInfoInStorage?.length) {
      ringtoneDevicesInfoInStorage = this._ringtoneDevices;
      this.setRingtoneDevices(this._ringtoneDevices);
    }

    return ringtoneDevicesInfoInStorage;
  }

  public getSignalingDevices(): Array<string> {
    const devices = this.getRingtoneDevices();

    if (devices?.length === 1 && devices[0] === this.sameAsOutputDevice.deviceId) {
      return [this.getVoiceAudioDevice()];
    } else {
      return devices;
    }
  }

  public getWorkItemSignalingDevices(): Array<string> {
    const devices = this.getWorkItemDevices();
    if (devices?.length === 1 && devices[0] === this.sameAsOutputDevice.deviceId) {
      return [this.getVoiceAudioDevice()];
    } else {
      return devices;
    }
  }

  public getVoiceAudioDevice(): string {
    let voiceAudioDeviceInfoInStorage = this._api.storage.get('voiceaudioDevice', SOUNDOPTION_STORAGE);

    if (typeof voiceAudioDeviceInfoInStorage === 'undefined') {
      voiceAudioDeviceInfoInStorage = this._voiceaudioDevice;
      this.setVoiceAudioDevice(this._voiceaudioDevice);
    }

    return voiceAudioDeviceInfoInStorage;
  }

  public getWorkItemDevices(): Array<string> {
    let workitemDevicesInStorage = this._api.storage.get('workitemDevices', SOUNDOPTION_STORAGE);

    if (!workitemDevicesInStorage?.length) {
      workitemDevicesInStorage = this._workitemDevices;
      this.setWorkItemDevices(this._workitemDevices);
    }

    return workitemDevicesInStorage;
  }

  public getInputDevice(): string {
    let inputDeviceInStorage = this._api.storage.get('inputDevice', SOUNDOPTION_STORAGE);

    if (typeof inputDeviceInStorage === 'undefined') {
      inputDeviceInStorage = this._inputDevice;
      this.setInputDevice(this._inputDevice);
    }

    return inputDeviceInStorage;
  }

  public async isMediaDeviceExistent(deviceId, category): Promise<any> {
    // we either have a string or an array for multiple ringtone devices
    if(typeof deviceId === 'string') {
      //if it is a string, check if the deviceId exists, and return it
      return this.checkForDeviceId(deviceId, category);
    } else if ( typeof deviceId === 'object') {
      //if it is an array, check every single deviceId and push it to the new array
      let alreadyHasDefault = false;
      let filteredDeviceIds = [];


      for (const singleDeviceId of deviceId) {
        const device = await this.checkForDeviceId(singleDeviceId, category);
        //if a device does not exist, we get default back. To prevent double default, we check if default is already set
        if(device === 'default' && !alreadyHasDefault) {
          alreadyHasDefault = true;
          filteredDeviceIds.push(device);
        } else if (device !== 'default') {
          filteredDeviceIds.push(device);
        }
      }
      //if we didn't get any devices, put default as the only device
      if(filteredDeviceIds.length === 0) {
        filteredDeviceIds[0] = 'default';
      }
      return filteredDeviceIds;
    }
    //fallback if no devices are set... shouldn't happen
    return 'default';
  }

  //function to check if a given deviceId exists
  public async checkForDeviceId(deviceId, category): Promise<string>  {
    //if the deviceId is sameAs*put, just return it
    if (deviceId === 'sameAsOutput' || deviceId === 'sameAsInput') return deviceId;

    //check if the category is input or output to easier filter the given lists
    if(category === 'input') {
      if (this.inputDeviceListObservable.some(device => device.deviceId === deviceId)) {
        return deviceId;
      }
    } else if ( category === 'output') {
      if (this.outputDeviceListObservable.some(device => device.deviceId === deviceId)) {
        return deviceId;
      }
    }
    //check if the deviceId exist, otherwise return default
    return 'default';
  }

  public setRingtoneVolume(value): void {
    this._logger.traceCall('ringtoneVolume', value);
    this._ringtoneVolume = value;
    if(this._audioElements.ringtone)
      this._audioElements.ringtone.volume = value / 100;
    this._api.storage.set('ringtoneVolume', value, SOUNDOPTION_STORAGE);
  }

  public setVoiceAudioVolume(value): void {
    this._logger.traceCall('voiceaudioVolume', value);
    this._voiceaudioVolume = value;
    if(this._audioElements.voice)
      this._audioElements.voice.volume = value / 100;
    // Publish the new Volume (see Voip.ts), to allow Volume change
    // while in a call
    Events.trigger(this._api.EVENT_LIST.SDK_AUDIO_VOLUME_CHANGE_VOICE, value);
    this._api.storage.set('voiceaudioVolume', value, SOUNDOPTION_STORAGE);
  }

  public setRingtoneDevices(deviceIds: Array<string>): void {
    this.isMediaDeviceExistent(deviceIds, 'output').then(deviceList => {
      this._logger.traceCall('ringtoneDevices', deviceList);
      this._ringtoneDevices = deviceList;
      this._api.storage.set('ringtoneDevices', deviceList, SOUNDOPTION_STORAGE);
    });
  }

  public async setVoiceAudioDevice(deviceId: string): Promise<void> {
    this.isMediaDeviceExistent(deviceId, 'output').then(device => {
      this._logger.traceCall('voiceaudioDevice', device);
      this._voiceaudioDevice = device;
      this._api.storage.set('voiceaudioDevice', device, SOUNDOPTION_STORAGE);
    });
  }

  public setWorkItemDevices(deviceIds: Array<string>): void {
    this.isMediaDeviceExistent(deviceIds, 'output').then(deviceList => {
      this._logger.traceCall('workitemDevices', deviceList);
      this._workitemDevices = deviceList;
      this._api.storage.set('workitemDevices', deviceList, SOUNDOPTION_STORAGE);
    });
  }

  public setInputDevice(deviceId: string): void {
    this.isMediaDeviceExistent(deviceId, 'input').then(device => {
      this._logger.traceCall('inputDevice', device);
      this._inputDevice = device;
      this._mediaConstraints.audio = {
        deviceId: device,
      };
      this._api.storage.set('inputDevice', device, SOUNDOPTION_STORAGE);
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////
  // ------------------------- /Localstorage soundoptions -----------------------------
  //////////////////////////////////////////////////////////////////////////////////

  @action private _updateDeviceList(devices: MediaDeviceInfo[]) {
    this.deviceListObservable = devices;
    this.outputDeviceListObservable = devices.filter(device => device.kind === 'audiooutput');
    this.inputDeviceListObservable = devices.filter(device => device.kind === 'audioinput');

    if (!this.getRingtoneDevices()?.length)
      this.setRingtoneDevices([this.sameAsOutputDevice.deviceId]);

    if (
      typeof this.getVoiceAudioDevice() === 'undefined' &&
      this.outputDeviceListObservable.length > 0
    ) {
      this.setVoiceAudioDevice(this.outputDeviceListObservable[0].deviceId);
    }

    if (typeof this.getInputDevice() === 'undefined' && this.inputDeviceListObservable.length > 0) {
      this.setInputDevice(this.inputDeviceListObservable[0].deviceId);
    }

    if (!this.getWorkItemDevices()?.length)
      this.setWorkItemDevices([this.sameAsOutputDevice.deviceId]);
  }

  @action public async requestPermissions(): Promise<boolean> {
    this._logger.trace('request Permissions');

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      stream.getTracks().forEach(track => {
        track.stop();
      });

      runInAction(() => {
        this._microphonePermission = 'granted';
      });
      this._logger.trace('microphone permission granted');
      Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
      return true;
    } catch (err) {
      runInAction(() => {
        this._microphonePermission = false;
      });
      this._logger.error('Could not obtain microphone permissions', { err });
      Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
      return false;
    }
  }

  @action public checkPermission = event => {
    this.checkDevices();
    if (event && event.target && event.target.state) {
      switch (event.target.state) {
      case 'granted':
        if (!this.hasMicrophonePermission) {
          runInAction(() => {
            this._microphonePermission = 'granted';
          });
          Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
        }
        break;
      case 'prompt':
        runInAction(() => {
          this._microphonePermission = 'prompt';
        });
        this.requestPermissions();
        break;
      default:
        if (this.hasMicrophonePermission) {
          runInAction(() => {
            this._microphonePermission = event.target.state;
          });
          Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
        }
        break;
      }
    }
  };

  @action public async checkDevices(): Promise<any> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      runInAction(() => {
        this._hasMicrophone = false;
      });
      this._hasSpeaker = false;

      runInAction(() => {
        this._updateDeviceList(devices);
      });

      devices.forEach(device => {
        //@ts-ignore
        const deviceInfo = device ? device.toJSON() : {};
        this._logger.trace('Device ', deviceInfo);

        if (device.kind === 'audioinput') {
          runInAction(() => {
            this._hasMicrophone = true;
          });
        }
        if (device.kind === 'audiooutput') {
          this._hasSpeaker = true;
        }
      });

      this._logger.trace('Check Devices ', {
        hasMicrophone          : this._hasMicrophone,
        hasSpeaker             : this._hasSpeaker,
        hasMicrophonePermission: this._microphonePermission,
      });

      if (this._hasMicrophone) {
        this.requestPermissions();
      } else {
        Events.trigger(EVENT_LIST.SDK_VOIP_STATE_CHANGED);
      }
    } catch (e) {
      runInAction(() => {
        this._hasMicrophone = false;
      });
      this._hasSpeaker = false;
      this._logger.error(e.message, e);
    }
  }

  _attachEventHandlers() {
    Events.on(EVENT_LIST.SDK_VOICE_ALERTING_START, () => !this._isRinging && this._startRinging());
    Events.on(EVENT_LIST.SDK_VOICE_ALERTING_END, () => this._isRinging && this._stopRinging());
    Events.on(EVENT_LIST.SDK_ME_WORKITEM_NEW, () => {
      const audioData = this._getNonVoiceAudioData();
      if (audioData) {
        this._createNonVoiceAudio(audioData);
        this._startNonVoiceSound();
      }
    });
    Events.on(EVENT_LIST.SDK_ME_BEFORE_RESET, () => {
      this._tmpStorage = {
        [SOUNDOPTION_STORAGE]      : this._api.storage.getContainer(SOUNDOPTION_STORAGE),
        [SOUNDCAPABILITIES_STORAGE]: this._api.storage.getContainer(SOUNDCAPABILITIES_STORAGE)
      };
    });
    Events.on(EVENT_LIST.SDK_ME_AFTER_RESET, () => {
      if (this._tmpStorage) {
        this._api.storage.setContainer(SOUNDOPTION_STORAGE, this._tmpStorage[SOUNDOPTION_STORAGE] ? this._tmpStorage[SOUNDOPTION_STORAGE] : undefined);
        this._api.storage.setContainer(SOUNDCAPABILITIES_STORAGE, this._tmpStorage[SOUNDCAPABILITIES_STORAGE] ? this._tmpStorage[SOUNDCAPABILITIES_STORAGE] : undefined);
        this._tmpStorage = undefined;
      }
    });
  }

  _registerActions() {
    /**
     * Usage:
     *
     * Play a nonvoice-beep:
     * sdk.actions.invoke('core.audio.play', {sound: "core.audio.non-voice-signal"})
     *
     * Play a ringtone for 2000ms:
     * sdk.actions.invoke('core.audio.play', {sound: "core.audio.ringtone", duration: 2000})
     *
     * Play a custom sound located at /multichannel/sdk/public/audio/custom/soundfile.mp3 using the non-voice device
     * sdk.actions.invoke('core.audio.play', {sound: "my-custom-sound-id", soundFile:"audio/custom/soundfile.mp3", soundDevice: "non-voice"})
     *
     * Play a nonvoice beep with 50% volume using the non-voice device
     * sdk.actions.invoke('core.audio.play', {sound: "my-half-volume-beep", soundFile:"audio/nonvoice.mp3", soundDevice: "non-voice", volume: 50})
     *
     * Play a ringtone with 50% volume using the ringtone device
     * sdk.actions.invoke('core.audio.play', {sound: "my-half-volume-ringtone", soundFile:"audio/telephone-ring.mp3", soundDevice: "ringtone", volume: 50})
     */
    Actions.register('core.audio.play', (params) => {
      const {
        sound = '',
        soundFile = '',
        soundDevice = '',
        duration,
        volume = 0
      } = params;

      this._logger.trace('trying to play a sound or soundfile', params);
      this._playAudioAction(sound, soundFile, soundDevice, duration, volume);
    });
  }

  /**
   * play a ringtone for a given duration, o non-voice-sound or any sound file located at /multichannel/sdk/public/audio/
   *
   * @param {string} soundId the id to play or to register as new sound
   * @param {string} soundFile only required for custom sound - the soundfile's path has to start with audio/
   * @param {'non-voice'|'ringtone'|'voice'} soundDevice only required for custom sound -  the device to play the sound on
   * @param {int} duration only required for soundId = 'core.audio.ringtone' - the duration to ring for
   * @param {int} volume only required for custom sound - the volume to play this sound with (0 for default device volume)
   */
  _playAudioAction(soundId = '', soundFile = '', soundDevice = '', duration = 0, volume = 0) {

    const params = {
      soundId    : soundId,
      soundFile  : soundFile,
      soundDevice: soundDevice,
      duration   : duration,
      volume     : volume
    };

    // rington without duration = error
    if (soundId == 'core.audio.ringtone' && duration <= 0) {
      this._logger.error('To play a ringtone, a duration is required.', { params: params });
      return;
    }
    // ringtone and ringing = nothing to do
    if (soundId == 'core.audio.ringtone' && this._isRinging) {
      return;
    }

    // play a custom sond file
    if (/^audio\/.*\.(mp3|wav)$/g.test(soundFile)) {
      return this._playCustomSound(soundId, soundFile, soundDevice, volume);
    }

    // play non voice signal
    if (soundId == 'core.audio.non-voice-signal') {
      this._startNonVoiceSound();
      return;
    }
    // play ringtone
    else if (soundId == 'core.audio.ringtone') {
      this._startRinging();
      setTimeout(() => {
        if (this._isRinging) {
          this._stopRinging();
        }
      }, duration);
      return;
    }

    // nothing matched so give error
    this._logger.error('Requested sound cannot be played because it does not exist.', { params: params });
  }

  /**
   * play any sound file located at /pcweb/gui/static/audio/
   *
   * @param {string} soundId the id to register this sound as
   * @param {string} soundFile the soundfile's path has to start with audio/
   * @param {'non-voice'|'ringtone'|'voice'} soundDevice the device to play the sound on
   * @param {int} volume the volume to play this sound with (0 for default device volume)
   */
  _playCustomSound(soundId = '', soundFile = '', soundDevice = '', volume = 0) {

    const params = {
      soundId    : soundId,
      soundFile  : soundFile,
      soundDevice: soundDevice,
      volume     : volume
    };

    const allowedDevices = {
      'non-voice': this._workitemDevices[0],
      'ringtone' : this._ringtoneDevices[0],
      'voice'    : this._voiceaudioDevice
    };

    const allowedDevicesVolume = {
      'non-voice': this.getWorkItemVolume(),
      'ringtone' : this.getRingtoneVolume(),
      'voice'    : navigator.userAgent.includes('Firefox') ? this.getRingtoneVolume() : this.getVoiceAudioVolume()
    };

    if (!allowedDevices.hasOwnProperty(soundDevice)) {
      this._logger.error('Custom sound cannot be played because the device is not allowed.', { params: params });
      return;
    }

    // TODO play on each device (if ringtone || nonvoice)...?
    const device = allowedDevices[soundDevice];

    if (device === undefined) {
      this._logger.error('Custom sound cannot be played because the device is not available for playback.', { params: params });
      return;
    }

    if (!allowedDevicesVolume[soundDevice] || allowedDevicesVolume[soundDevice] <= 0) {
      this._logger.trace('Sound could not be played because volume was 0', { params: params });
      return;
    }

    if (!this._customAudioElements[soundId]) {
      this._logger.trace('Creating custom audio element for custom sound.', { params: params });

      this._customAudioElements[soundId] = new Audio();
      this._customAudioElements[soundId].id = 'soundId';
      this._customAudioElements[soundId].loop = false;
      this._customAudioElements[soundId].autoplay = false;
      this._customAudioElements[soundId].src = soundFile;

      if (volume > 0) {
        this._customAudioElements[soundId].volume = volume / 100;
      }
      else {
        this._customAudioElements[soundId].volume = allowedDevicesVolume[soundDevice] / 100;
      }

      if (this._customAudioElements[soundId].volume !== 0 && this._customAudioElements[soundId].setSinkId !== undefined) {
        this._customAudioElements[soundId].setSinkId(device);
      }
    }

    this._logger.trace('Playing custom sound');

    this._customAudioElements[soundId].play().catch(e => {
      this._logger.error('custom sound not played ', e);
    });
  }

  _getNonVoiceAudioData() {
    const url = this._api.currentWorkItem?.group?.getNotificationEvents('ReceivedIncoming')?.pop()?.audiourl;
    return url ? { id: url + '-nonvoice', src: 'audio/' + url?.substring(url?.lastIndexOf('/') + 1) } : null;
  }

  _createNonVoiceAudio(data: { id: string, src: string } | null) {
    const { id, src } = data || { id: 'pcweb-gui-nonvoice', src: 'audio/nonvoice.mp3' };

    if (!this._audioElements.nonvoice || this._audioElements.nonvoice.getAudio()?.id !== id) {

      this._logger.trace('Creating audio element for nonvoice');

      let audio = new Audio();
      audio.id  = id;
      audio.loop  = false;
      audio.autoplay= false,
      audio.src    = src;

      this._audioElements.nonvoice = new AudioCollection(audio);
    }
  }

  _startNonVoiceSound() {
    this._logger.trace('Starting nonvoice sound');
    this.checkAudioContext();

    if (!this.isRingingAllowed) {
      this._logger.trace('Ringing is not allowed', {
        instanceId  : this._api.instanceId,
        instances   : this._api.instanceList,
        capabilities: this.audioInstanceList,
        contextState: this._audioContext?.state || 'unavailable'
      });
      return;
    }

    if (this._audioElements?.nonvoice) {
      this._audioElements.nonvoice.reset();
      this._audioElements.nonvoice.volume = this.getWorkItemVolume() / 100;
    }
    if (this._audioElements?.nonvoice?.volume !== 0) {
      // 18892: (2020/01/07) Firefox currently doesn't enable setsinkId bu default
      if (
        this.getWorkItemSignalingDevices()?.length &&
        this._audioElements.nonvoice.setSinkId !== undefined
      ) {
        this.getWorkItemSignalingDevices().forEach(deviceId => {
          let audio = this._audioElements.nonvoice.getAudio();
          audio.setSinkId(deviceId).then(() => {
            audio.play().catch(e => {
              this._logger.error('nonvoice sound not played ', e);
            });
          });

        });
      } else {
        this._playNonVoiceSound();
      }
    }
  }

  _playNonVoiceSound() {
    this._audioElements.nonvoice.getAudio().play().catch(e => {
      this._logger.error('nonvoice sound not played ', e);
    });
  }

  @action public checkAudioContext() {
    this._audioContextIssue = this._audioContextGranted ? false : true;
  }

  @computed public get isRingingAllowed() {
    // Check, if all other Instances are not able to Play
    const instances = this._api.instanceList;
    const capabilities = this.audioInstanceList;

    const audioTabInstance = instances.find(instanceId => {
      const audioCapabilities = capabilities.find(item => item.instanceId === instanceId);
      return audioCapabilities ? audioCapabilities.audioContext : false; // OTRS: https://otrs.4com.de/otrs/index.pl?Action=AgentTicketZoom;TicketID=161807;ArticleID=444510#440240
    });

    return audioTabInstance === this._api.instanceId ||
      !instances ||
      (instances.length <= 1 && this._audioContext?.state === AudioContextState.RUNNING); //OTRS: https://4com.kanbanize.com/ctrl_board/5/cards/39348/details/
  }

  /**
   * Load Ringtone on page load
   * @see https://4com.kanbanize.com/ctrl_board/5/cards/39876/details/
   */
  _createRingtoneAudio() {
    if (typeof this._audioElements.ringtone === 'undefined') {
      this._logger.trace('Creating audio element for ringtone');

      let audio = new Audio();
      audio.id  ='pcweb-gui-ringtone',
      audio.loop  = true,
      audio.autoplay= false,
      audio.src    = 'audio/telephone-ring.mp3';

      this._audioElements.ringtone = new AudioCollection(audio);
    }
  }

  @action _startRinging(isTestSound = false) {
    this.checkAudioContext();

    if (!this.isRingingAllowed) {
      this._logger.trace('Ringing is not allowed', {
        instanceId  : this._api.instanceId,
        instances   : this._api.instanceList,
        capabilities: this.audioInstanceList,
        contextState: this._audioContext?.state || 'unavailable'
      });
      return;
    }

    this._isRinging = true;
    this._isRingingTest = isTestSound;

    this._logger.trace('Starting ringtone');

    this._audioElements.ringtone.reset();
    this._audioElements.ringtone.volume = this.getRingtoneVolume() / 100;

    if (this._audioElements.ringtone.volume !== 0) {
      // 18892: (2020/01/07) Firefox currently doesn't enable setsinkId bu default
      if (
        this.getRingtoneDevices()?.length &&
        this._audioElements.ringtone.setSinkId !== undefined
      ) {
        this.getSignalingDevices().forEach(deviceId => {
          let audio = this._audioElements.ringtone.getAudio();
          audio.setSinkId(deviceId).then(() => {
            audio.play().catch(e => {
              this._logger.error('ringtone sound not played ', e);
            });
          });
        });
      } else {
        this._playRingtone();
      }
    }
  }

  _playRingtone() {
    this._audioElements.ringtone.getAudio().play().catch(e => {
      this._logger.error('ringtone sound not played ', e);
    });
  }

  @action _stopRinging() {
    this._isRinging = false;
    this._isRingingTest = false;

    this._logger.trace('Stopping ringtone');

    this._audioElements.ringtone.reset();
  }

  _createVoiceAudio() {
    if (!this._audioElements.voice) {
      this._logger.trace('Creating audio element for ringtone');

      this._audioElements.voice = new Audio();
      this._audioElements.voice.id = 'pcweb-gui-voice';
      this._audioElements.voice.loop = true;
      this._audioElements.voice.autoplay = false;
      this._audioElements.voice.src = 'audio/telephone-ring.mp3';
    }
  }

  @action _startVoiceSound() {
    this.checkAudioContext();

    if (!this.isRingingAllowed) {
      this._logger.trace('Voice Audio is not allowed', {
        instanceId  : this._api.instanceId,
        instances   : this._api.instanceList,
        capabilities: this.audioInstanceList,
        contextState: this._audioContext?.state || 'unavailable'
      });
      return;
    }

    this._isVoicePlaying = true;

    this._logger.trace('Starting Test Voice');

    this._audioElements.voice.pause();
    this._audioElements.voice.volume = this.getVoiceAudioVolume() / 100;
    this._audioElements.voice.currentTime = 0;
    if (this._audioElements.voice.volume !== 0) {
      // 18892: (2020/01/07) Firefox currently doesn't enable setsinkId bu default
      if (
        this.getVoiceAudioDevice() !== undefined &&
        this._audioElements.voice.setSinkId !== undefined
      ) {
        this._audioElements.voice
          .setSinkId(this.getVoiceAudioDevice())
          .then(this._playVoiceSound());
      } else {
        this._playVoiceSound();
      }
    }
  }

  _playVoiceSound() {
    this._audioElements.voice.play().catch(e => {
      this._logger.error('voice sound not played ', e);
    });
  }

  @action _stopVoiceSound() {
    this._isVoicePlaying = false;

    this._logger.trace('Stopping Voice sound');

    this._audioElements.voice.pause();
    this._audioElements.voice.currentTime = 0;
  }

  @action _setSwitchDeviceAbility() {
    this._canSwitchDevices = this._audioElements.nonvoice.setSinkId !== undefined;
  }

  @computed public get canSwitchDevices() {
    return this._canSwitchDevices;
  }

}

class AudioCollection {
  private _free:Array<any>;
  private _busy:Array<any>;

  constructor(audio) {
    this._free = [audio];
    this._busy = [];
  }
  private get pool():Array<any> {
    return [...this._free, ...this._busy];
  }
  public get volume():number {
    return this.pool[this.pool.length - 1]?.volume;
  }
  public set volume(value:number) {
    this.pool.map(audio => audio.volume = value);
  }
  public get setSinkId():any {
    return this.pool[this.pool.length - 1]?.setSinkId;
  }
  public set currentTime(value:number) {
    this.pool.map(audio => audio.currentTime = value);
  }
  public getAudio():any {
    let audio = this._free.length ? this._free.pop() :
      this._busy[this._busy.length - 1]?.cloneNode();
    this._busy.push(audio);
    return audio;
  }
  public reset():void {
    this.pool.map(audio => {
      audio.pause();
      audio.currentTime = 0;
    });
    this._free.push(...this._busy.splice(0));
  }
}

interface AudioElements {
  ringtone?: any;
  nonvoice?: any;
  voice?: any;
}

interface SimpleMediaDeviceInfo {
  deviceId: string;
  label: string;
}

enum AudioContextState {
  SUSPENDED = 'suspended',
  RUNNING = 'running',
  CLOSED = 'closed'
}

export interface AudioCapabilities {
  audioContext: boolean;
  instanceId: string;
}